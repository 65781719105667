import React from 'react';
import "../styles/TopBar.css";

export default function TopBar() {
  return (
    <div className="topbar" role="banner">
      Handmade saxophone and clarinet mouthpieces.
    </div>
  );
}
