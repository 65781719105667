export const locationData = [
  {
    name: "Bailey Brothers Music",
    address: "4673 US-280 Suite # 7",
    city: "Birmingham",
    state: "AL",
    zip: "35242",
    country: "USA",
    phone: "(205) 271-7827",
    website: "https://www.baileybrothers.com/",
    latitude: 33.47125001160268,
    longitude: -86.6915811142433,
  },
  {
    name: "Chicago Music Store",
    address: "5646 E. Speedway Blvd.",
    city: "Tucson",
    state: "AZ",
    zip: "85712",
    country: "USA",
    phone: "(520) 622-3341",
    website: "http://www.chicagomusicstore.com/",
    latitude: 32.221541069836064,
    longitude: -110.96846939688903,
  },
  {
    name: "Milano Music",
    address: "38 W. Main St.",
    city: "Mesa",
    state: "AZ",
    zip: "85201",
    country: "USA",
    phone: "(480) 827-1111",
    website: "http://www.milanomusic.com/",
    latitude: 33.415900849073346,
    longitude: -111.83301559291436,
  },
  {
    name: "Sam Ash Music #61",
    address: "4402 W. Cactus Rd.",
    city: "Glendale",
    state: "AZ",
    zip: "85304",
    country: "USA",
    phone: "(602) 863-7746",
    website: "https://locations.samash.com/az/glendale/61/",
    latitude: 33.59762382309207,
    longitude: -112.15259141820425,
  },
  {
    name: "Campos Music & Supplies",
    address: "1241 E Broadway Rd APT 24",
    city: "Mesa",
    state: "AZ",
    zip: "85204",
    country: "USA",
    phone: "480 610 4647",
    website: "http://www.camposmusicusa.com/",
    latitude: 33.40774988970538,
    longitude: -111.8043251473544,
  },
  {
    name: "A and G Music Products",
    address: "564 14th St.",
    city: "Oakland",
    state: "CA",
    zip: "94612",
    country: "USA",
    phone: "(510) 832-0381",
    website: "www.agmusic.com",
    latitude: 37.80585321465971,
    longitude: -122.27420394504423,
  },
  {
    name: "American Music Co.",
    address: "2597 E. Ashlan Ave.",
    city: "Fresno",
    state: "CA",
    zip: "93726",
    country: "USA",
    phone: "(559) 221-0233",
    website: "http://www.americanmusicsales.com/",
    latitude: 36.79455914117891,
    longitude: -119.78054173189693,
  },
  {
    name: "Music & Arts #2604",
    address: "504 S. State College Blvd.",
    city: "Anaheim",
    state: "CA",
    zip: "92806",
    country: "USA",
    phone: "(714) 999-5015",
    website: "https://stores.musicarts.com/ca/anaheim/music-store-2604.html",
    latitude: 33.83186,
    longitude: -117.893313,
  },
  {
    name: "A-Hot Latinos",
    address: "2105 Edison Hwy Space 24",
    city: "Bakersfield",
    state: "CA",
    zip: "93305",
    country: "USA",
    phone: "(661) 363-3825",
    website: "https://www.facebook.com/AHotLatinos/",
    latitude: 35.371367790915556,
    longitude: -118.97101001844786,
  },
  {
    name: "Bellamusic",
    address: "3922 Florence Ave Suite F",
    city: "Bell",
    state: "CA",
    zip: "90201",
    country: "USA",
    phone: "323 691 4650",
    website: "https://bellamusic.business.site/?m=true",
    latitude: 33.97170127518447,
    longitude: -118.19727442602408,
  },

  {
    name: "Bellflower Music Center",
    address: "17125 Bellflower Blvd.",
    city: "Bellflower",
    state: "CA",
    zip: "90706",
    country: "USA",
    phone: "(562) 867-6715",
    website: "http://www.bellflowermusic.com/",
    latitude: 33.877839820883516,
    longitude: -118.12541447589764,
  },
  {
    name: "C & L Music",
    address: "2395 Monument Blvd. #B",
    city: "Concord",
    state: "CA",
    zip: "94520",
    country: "USA",
    phone: "(925) 686-6122",
    website: "https://www.yelp.com/biz/c-and-l-music-concord",
    latitude: 37.96059466959283,
    longitude: -122.03569263154608,
  },
  {
    name: "Stomvi USA",
    address: "24658 Walnut Street",
    city: "Newhall",
    state: "CA",
    zip: "91321",
    country: "USA",
    phone: "(661) 253-0066",
    website: "https://www.stomvi-usa.com/",
    latitude: 34.38335663982698,
    longitude: -118.53250597301556,
  },
  {
    name: "Cassell's Music",
    address: "901 N. Maclay Street",
    city: "San Fernando",
    state: "CA",
    zip: "91340",
    country: "USA",
    phone: "(818)365-9247",
    website: "http://www.cassellsmusic.com/",
    latitude: 34.292944848588796,
    longitude: -118.42864053599361,
  },
  {
    name: "Constellation Musical Instruments",
    address: "3701 W McFadden Ave",
    city: "Santa Ana",
    state: "CA",
    zip: "92704",
    country: "USA",
    phone: "714 418 0186",
    website: "http://www.constellationmusical.com/",
    latitude: 33.73877118895468,
    longitude: -117.91913767884436,
  },
  {
    name: "Delilah's Music Store",
    address: "1739 S Oxnard Blvd",
    city: "Oxnard",
    state: "CA",
    zip: "93030",
    country: "USA",
    phone: "805 616 8003",
    website: "http://www.delilahsmusicstore.com/",
    latitude: 34.18284353483966,
    longitude: -119.16868368280483,
  },
  {
    name: "Imperial Band Instruments",
    address: "15847 Imperial Hwy",
    city: "La Mirada",
    state: "CA",
    zip: "90638",
    country: "USA",
    phone: "562 245 6043",
    website: "http://www.imperial-band.com/",
    latitude: 33.918309561852574,
    longitude: -117.99243720243528,
  },
  {
    name: "International House of Music Inc.",
    address: "14061 Newport Ave,",
    city: "Tustin",
    state: "CA",
    zip: "92780",
    country: "USA",
    phone: "714 669 3600",
    website: "https://ihomi.com/",
    latitude: 33.73725334786751,
    longitude: -117.82568883128035,
  },
  {
    name: "Kline Music",
    address: "2200 Sutterville Rd",
    city: "Sacramento",
    state: "CA",
    zip: "95822",
    country: "USA",
    phone: "916 456 8742",
    website: "http://www.klinemusic.com/",
    latitude: 38.538678671546776,
    longitude: -121.48870041349223,
  },
  {
    name: "Music & Arts #2602",
    address: "650E Parkridge Ave. Suite 113/115",
    city: "Corona",
    state: "CA",
    zip: "92879",
    country: "USA",
    phone: "(951) 735-5924",
    website: "https://stores.musicarts.com/ca/corona/music-store-2602.html",
    latitude: 33.88663595119017,
    longitude: -117.55683875682142,
  },
  {
    name: "Music Village",
    address: "2971 Union Ave.",
    city: "San Jose",
    state: "CA",
    zip: "95124",
    country: "USA",
    phone: "(408) 377-2504",
    website: "http://www.musvil.com/",
    latitude: 37.27654006535496,
    longitude: -121.93380651556099,
  },

  {
    name: "Tim's Music",
    address: "6818 Fair Oaks Blvd.",
    city: "Carmichael",
    state: "CA",
    zip: "95608",
    country: "USA",
    phone: "(916) 925-9160",
    website: "http://www.timsmusicsacramento.com/",
    latitude: 38.62497098188736,
    longitude: -121.32789621164075,
  },

  {
    name: "Music & Arts #2608",
    address: "848 S. Grand Ave.",
    city: "Glendora",
    state: "CA",
    zip: "91740",
    country: "USA",
    phone: "(626) 963-0263",
    website: "https://stores.musicarts.com/ca/glendora/music-store-2608.html",
    latitude: 34.12368628758029,
    longitude: -117.871909316336,
  },

  {
    name: "International House of Music",
    address: "821 S Los Angeles St",
    city: "Los Angeles",
    state: "CA",
    zip: "90014",
    country: "USA",
    phone: "(213) 628-9161",
    website: "http://www.ihomi.com/",
    latitude: 34.04183949724287,
    longitude: -118.25304470284614,
  },

  {
    name: "Nick Rail Music",
    address: "5023 Kanan Rd.",
    city: "Agoura Hills",
    state: "CA",
    zip: "91301",
    country: "USA",
    phone: "(818) 991-3750",
    website: "https://www.nickrailmusic.com/t-Stores.agourahills.aspx",
    latitude: 34.145261932400395,
    longitude: -118.76275250361354,
  },
  {
    name: "Nick Rail Music",
    address: "5127 Ming Ave. Floor 1 Colonial Square",
    city: "Bakersfield",
    state: "CA",
    zip: "93309",
    country: "USA",
    phone: "(661) 412-4942",
    website: "http://www.nickrailmusic.com/",
    latitude: 35.33767390675245,
    longitude: -119.05957652978755,
  },
  {
    name: "Nick Rail Music",
    address: "1770 Orange Tree Ln.",
    city: "Redlands",
    state: "CA",
    zip: "92374",
    country: "USA",
    phone: "(909) 798-9998",
    website: "http://www.nickrailmusic.com/",
    latitude: 34.0683937220281,
    longitude: -117.21502146051704,
  },
  {
    name: "Nick Rail Music",
    address: "2801 De La Vina St.",
    city: "Santa Barbara",
    state: "CA",
    zip: "93105",
    country: "USA",
    phone: "(805) 569-5353",
    website: "https://www.nickrailmusic.com/t-Stores.santabarbara.aspx",
    latitude: 34.43669364556087,
    longitude: -119.72646261632545,
  },
  {
    name: "Nick Rail Music",
    address: "25868 McBean Pkwy.",
    city: "Valencia",
    state: "CA",
    zip: "91355",
    country: "USA",
    phone: "(661) 288-2345",
    website: "http://www.nickrailmusic.com/",
    latitude: 34.40369436756586,
    longitude: -118.55045431632658,
  },
  {
    name: "Olvera Music",
    address: "5110 Whittier Blvd.",
    city: "East Los Angeles",
    state: "CA",
    zip: "90022",
    country: "USA",
    phone: "(323) 269-4935",
    website: "http://www.olveramusic.com/",
    latitude: 34.02089603675696,
    longitude: -118.15944635866747,
  },

  {
    name: "Salinas Music 123",
    address: "1111 N Main St. C",
    city: "Salinas",
    state: "CA",
    zip: "93906-3600",
    country: "USA",
    phone: "(831) 998-8950",
    website: "https://salinasmusic123.com/",
    latitude: 36.69637105636771,
    longitude: -121.65230773000944,
  },

  {
    name: "Sam Ash Music #41",
    address: "4449 Mills Cir",
    city: "Ontario",
    state: "CA",
    zip: "91764",
    country: "USA",
    phone: "(909) 484-3550",
    website: "https://locations.samash.com/ca/ontario/41/",
    latitude: 34.07431396131425,
    longitude: -117.55407530548055,
  },
  {
    name: "San Diego County Music Exchange",
    address: "985 Civic Center Dr. #102",
    city: "Vista",
    state: "CA",
    zip: "92083",
    country: "USA",
    phone: "(760) 630-7451",
    website: "http://www.sdmusicexchange.com/",
    latitude: 33.19237361907186,
    longitude: -117.23648773266007,
  },
  {
    name: "Sam Ash Music #32",
    address: "5460 West Sample Road",
    city: "Margate",
    state: "FL",
    zip: "33073",
    country: "USA",
    phone: "(954) 975-3390",
    website:
      "https://locations.samash.com/fl/margate/32/?utm_source=Google&utm_medium=Maps&utm_campaign=Google+Places",
    latitude: 28.71183460117081,
    longitude: -80.34494825273399,
  },
  {
    name: "Sam Ash Music #33",
    address: "5360 NW 167th St.",
    city: "Miami Lakes",
    state: "FL",
    zip: "33014",
    country: "USA",
    phone: "(305) 628-3510",
    website:
      "https://locations.samash.com/fl/miami-lakes/33/?utm_source=Google&utm_medium=Maps&utm_campaign=Google+Places",
    latitude: 25.93040754297409,
    longitude: -80.28675559857498,
  },

  {
    name: "Sam Ash Music #42",
    address: "923 McMullen Booth Road",
    city: "Clearwater",
    state: "FL",
    zip: "33759",
    country: "USA",
    phone: "727 725 8062",
    website:
      "https://locations.samash.com/fl/clearwater/42/?utm_source=Google&utm_medium=Maps&utm_campaign=Google+Places",
    latitude: 27.974383677290717,
    longitude: -82.7081338828048,
  },

  {
    name: "Sam Ash Music #47",
    address: "7606 N. Tamiami Trail",
    city: "Sarasota",
    state: "FL",
    zip: "34243",
    country: "USA",
    phone: "(941) 351-7793",
    website: "https://locations.samash.com/fl/sarasota/47/",
    latitude: 27.405480520923195,
    longitude: -82.56865617116406,
  },
  {
    name: "Sam Ash Music #46",
    address: "912 Lee Rd.",
    city: "Orlando",
    state: "FL",
    zip: "32810",
    country: "USA",
    phone: "(407) 599-1222",
    website: "https://locations.samash.com/fl/orlando/46/",
    latitude: 28.604678211978605,
    longitude: -81.39592476428523,
  },
  {
    name: "Sam Ash Music #52",
    address: "Dolphin Mall, 11421 NW 12th St.",
    city: "Miami",
    state: "FL",
    zip: "33172",
    country: "USA",
    phone: "(786) 331-9688",
    website: "https://locations.samash.com/fl/miami/52/",
    latitude: 25.788019286294222,
    longitude: -80.38054487581303,
  },
  {
    name: "Sam Ash Music #70",
    address: "8151 Blanding Blvd.",
    city: "Jacksonville",
    state: "FL",
    zip: "32244",
    country: "USA",
    phone: "904 855 2905",
    website: "https://locations.samash.com/fl/jacksonville/70/",
    latitude: 30.199270450536606,
    longitude: -81.73859873091611,
  },
  {
    name: "Jackson's Music Store",
    address: "1651 Horizon Pkwy #500",
    city: "Buford",
    state: "GA",
    zip: "30518",
    country: "USA",
    phone: "770 904 2535",
    website: "http://www.jacksonsmusic.com/",
    latitude: 34.082892006098476,
    longitude: -83.99725001349222,
  },

  {
    name: "Carere Music Inc.",
    address: "6395 Spalding Dr H",
    city: "Peachtree Corners",
    state: "GA",
    zip: "30092",
    country: "USA",
    phone: "(770) 671-0517",
    website: "https://careremusic.com/",
    latitude: 33.9691492064757,
    longitude: -84.25798304702796,
  },
  {
    name: "Music Centers of Hawaii",
    address: "2347 S Beretania St. #200",
    city: "Honolulu",
    state: "HI",
    zip: "96826",
    country: "USA",
    phone: "(808) 596-8742",
    website: "https://www.musiccenterofhawaii.com/",
    latitude: 21.297227209816892,
    longitude: -157.82464283228808,
  },
  {
    name: "Austin Music Center",
    address: "6815 W. North Ave.",
    city: "Oak Park",
    state: "IL",
    zip: "60302",
    country: "USA",
    phone: "(708) 383-1961",
    website: "http://www.austinmusiccenter.net/",
    latitude: 41.90900386072478,
    longitude: -87.79578353139658,
  },
  {
    name: "Ceasar's Music of Illinois",
    address: "3407 W 26th St.",
    city: "Chicago",
    state: "IL",
    zip: "60623",
    country: "USA",
    phone: "(773) 319-6372",
    latitude: 41.84441877015857,
    longitude: -87.7103523718764,
  },

  {
    name: "Evanston Band & Orchestra",
    address: "4141 Main Street",
    city: "Skokie",
    state: "IL",
    zip: "60076",
    country: "USA",
    phone: "847 673 3812",
    website: "http://www.bandandorch.com/",
    latitude: 42.03352463126682,
    longitude: -87.73193381733225,
  },

  {
    name: "Sam Ash #20",
    address: "1139 S. Main St.",
    city: "Lombard",
    state: "IL",
    zip: "60148",
    country: "USA",
    phone: "(630) 424-0767",
    website: "https://locations.samash.com/il/lombard/20/",
    latitude: 41.874474177303476,
    longitude: -88.01913353112197,
  },

  {
    name: "Iggy Music Store",
    address: "307 E North Ave, ",
    city: "Northlake",
    state: "IL",
    zip: "60164",
    country: "USA",
    phone: "(847) 328-2664",
    latitude: 41.90707717395022,
    longitude: -87.89244586208358,
  },
  {
    name: "Kagan and Gaines Music Co.",
    address: "7655 Roosevelt Rd",
    city: "Forest Park",
    state: "IL",
    zip: "60130",
    country: "USA",
    phone: "(708) 771-2152",
    website: "http://www.kaganandgaines.com/",
    latitude: 41.865093760505054,
    longitude: -87.81599471420382,
  },

  {
    name: "PM Woodwind Repair, Inc.",
    address: "822 Custer Ave.",
    city: "Evanston",
    state: "IL",
    zip: "60202",
    country: "USA",
    phone: "(847) 869-7049",
    website: "https://pmwoodwind.com/",
    latitude: 42.03322200111355,
    longitude: -87.68035009885376,
  },

  {
    name: "Sam Ash Music #18",
    address: "1305 W. Dundee Rd.",
    city: "Buffalo Grove",
    state: "IL",
    zip: "60089",
    country: "USA",
    phone: "(847) 253-3151",
    website: "https://locations.samash.com/il/buffalo-grove/18/",
    latitude: 42.138022423770224,
    longitude: -87.98714517281287,
  },
  {
    name: "Woodwind and Brasswind",
    address: "4004 Technology Dr.",
    city: "South Bend",
    state: "IN",
    zip: "46628",
    country: "USA",
    phone: "(574) 251-3500",
    website: "https://www.wwbw.com/",
    latitude: 41.710905,
    longitude: -86.25061,
  },

  {
    name: "Sam Ash Music #57",
    address: "8284 Center Run Dr.",
    city: "Indianapolis",
    state: "IN",
    zip: "46250",
    country: "USA",
    phone: "(317) 577-3006",
    website: "https://locations.samash.com/in/indianapolis/57/",
    latitude: 39.908222625495114,
    longitude: -86.05870643439869,
  },

  {
    name: "Chuck Levin's Washington Music Center",
    address: "11151 Veirs Mill Rd.",
    city: "Wheaton",
    state: "MD",
    zip: "20902",
    country: "USA",
    phone: "(301) 946-8808",
    website: "http://www.chucklevins.com/",
    latitude: 39.03925646334514,
    longitude: -77.05349799081043,
  },
  {
    name: "L & L Music-Wind Shop",
    address: "7 Metropolitan Ct #3",
    city: "Gaithersburg",
    state: "MD",
    zip: "20878",
    country: "USA",
    phone: "(301) 948-7273",
    website: "http://llmusicshop.com/",
    latitude: 39.15000809619052,
    longitude: -77.2291723449948,
  },

  {
    name: "Detroit Wayne Music Studio",
    address: "21301 Gratiot Ave.",
    city: "Eastpointe",
    state: "MI",
    zip: "48021",
    country: "USA",
    phone: "(586)-822-1977",
    website: "http://saxophonecenter.com/",
    latitude: 42.45464127121395,
    longitude: -82.96522151505624,
  },
  {
    name: "MFI Musician's Friend",
    address: "Distribution Center 4001 N. Norfleet Rd",
    city: "Kansas City",
    state: "MO",
    zip: "64161",
    country: "USA",
    phone: "877 514 5686",
    website: "https://www.musiciansfriend.com/",
    latitude: 39.16931053994552,
    longitude: -94.4217349711777,
  },

  {
    name: "Groth Music Company",
    address: "8056 Nicollet Ave.",
    city: "Bloomington",
    state: "MN",
    zip: "55420",
    country: "USA",
    phone: "(952) 884-4772",
    website: "http://www.grothmusic.com/",
    latitude: 44.85746131270546,
    longitude: -93.27944344508242,
  },
  {
    name: "Dietze Music Omaha",
    address: "13015 W Center Rd Suite 1A-B",
    city: "Omaha",
    state: "NE",
    zip: "68144",
    country: "USA",
    phone: "(402) 333-1535",
    website: "https://www.dietzemusic.com/",
    latitude: 41.232846204063,
    longitude: -96.11748600258717,
  },

  {
    name: "Sam Ash Music #02",
    address: "269 Old Walt Whitman Rd.",
    city: "Huntington Station",
    state: "NY",
    zip: "11746",
    country: "USA",
    phone: "(631) 421-9333",
    website: "https://locations.samash.com/ny/huntington-station/2/",
    latitude: 40.81992989101077,
    longitude: -73.41276288911082,
  },
  {
    name: "Sam Ash Music #05",
    address: "2600 Flatbush Ave.",
    city: "Brooklyn",
    state: "NY",
    zip: "11234",
    country: "USA",
    phone: "(718) 951-3888",
    website: "https://locations.samash.com/ny/brooklyn/5/",
    latitude: 40.60729822797139,
    longitude: -73.92024168855929,
  },
  {
    name: "Sam Ash Music #06",
    address: "178 Mamaroneck Ave.",
    city: "White Plains",
    state: "NY",
    zip: "10601",
    country: "USA",
    phone: "(914) 949-8448",
    website: "https://locations.samash.com/ny/white-plains/6/",
    latitude: 41.028660393349874,
    longitude: -73.76523442958002,
  },
  {
    name: "Alto Music",
    address: "180 Carpenter Ave.",
    city: "Middletown",
    state: "NY",
    zip: "10940",
    country: "USA",
    phone: "845 692 6922",
    website: "https://www.altomusic.com/",
    latitude: 41.45366153608014,
    longitude: -74.38946193271046,
  },
  {
    name: "Weiner Music Inc.",
    address: "168 Jericho Turnpike",
    city: "Mineola",
    state: "NY",
    zip: "11501",
    country: "USA",
    phone: "516 747 5004",
    website: "http://shop.weinermusic.com/",
    latitude: 40.749164366624456,
    longitude: -73.6423379503068,
  },

  {
    name: "Hickey's Music Center",
    address: "104 Adams St.",
    city: "Ithaca",
    state: "NY",
    zip: "14850",
    country: "USA",
    phone: "607 272 8262",
    website: "http://www.hickeys.com/",
    latitude: 42.4497131670189,
    longitude: -76.5014699191848,
  },

  {
    name: "Sam Ash Music #03",
    address: "385 Old Country Road",
    city: "Carle Place",
    state: "NY",
    zip: "11514",
    country: "USA",
    phone: "(516) 333-8700",
    website: "https://locations.samash.com/ny/carle-place/3/",
    latitude: 40.746131551886926,
    longitude: -73.60121586346628,
  },
  {
    name: "Sam Ash Music #04",
    address: "113-25 Queens Boulevard",
    city: "Forest Hills",
    state: "NY",
    zip: "11375",
    country: "USA",
    phone: "(718) 793-7983",
    website: "https://locations.samash.com/ny/forest-hills/4/",
    latitude: 40.7178140329168,
    longitude: -73.8348457886011,
  },
  {
    name: "Sam Ash Music #07",
    address: "333 W. 34th St.",
    city: "New York",
    state: "NY",
    zip: "10001",
    country: "USA",
    phone: "(212) 719-2299",
    website: "https://locations.samash.com/ny/new-york/7/",
    latitude: 40.75350805561746,
    longitude: -73.99481601743486,
  },
  {
    name: "Libby & Leaf/Zentra LLC",
    address: "110 Associates Ln",
    city: "Indian Trail",
    state: "NC",
    zip: "28079",
    country: "USA",
    phone: "(704)-684-5721",
    website: "https://www.zentrallc.com/",
    latitude: 35.07730864011492,
    longitude: -80.66082123426064,
  },

  {
    name: "Music & Arts",
    address: "1258 S. Church St.",
    city: "Burlington",
    state: "NC",
    zip: "27215",
    country: "USA",
    phone: "(336) 584-3338",
    website: "https://stores.musicarts.com/nc/burlington/music-store-0063.html",
    latitude: 36.08757995836829,
    longitude: -79.45062781812025,
  },

  {
    name: "Popplers Music Store",
    address: "1726 S Washington St S5",
    city: "Grand Forks",
    state: "ND",
    zip: "58201",
    country: "USA",
    phone: "(701) 746-7471",
    website: "http://www.popplersmusic.com/",
    latitude: 47.903199666105046,
    longitude: -97.04751501697068,
  },

  {
    name: "Sam Ash Music #53",
    address: "2747 S. Maryland Pkwy",
    city: "Las Vegas",
    state: "NV",
    zip: "89109",
    country: "USA",
    phone: "(702) 734-0007",
    website: "https://locations.samash.com/nv/las-vegas/53/",
    latitude: 36.1400489751871,
    longitude: -115.13769530313702,
  },

  {
    name: "Motters Music House, Inc.",
    address: "5220 Mayfield Rd.",
    city: "Cleveland",
    state: "OH",
    zip: "44124",
    country: "USA",
    phone: "(440) 442-7470",
    website: "https://musiciselementary.com/",
    latitude: 41.519593797215045,
    longitude: -81.49144864860634,
  },
  {
    name: "Sam Ash Music #35",
    address: "5700 Mayfield Rd.",
    city: "Lyndhurst",
    state: "OH",
    zip: "44124",
    country: "USA",
    phone: "(440) 446-0850",
    website: "https://locations.samash.com/oh/lyndhurst/35/",
    latitude: 41.51872263271571,
    longitude: -81.47410453141197,
  },

  {
    name: "Saied Music Company",
    address: "3259 S Yale Ave",
    city: "Tulsa",
    state: "OK",
    zip: "74135",
    country: "USA",
    phone: "(918) 742-5541",
    website: "http://www.saiedmusic.com/",
    latitude: 36.11595732379918,
    longitude: -95.9219967506573,
  },
  {
    name: "Tulsa Band Instruments",
    address: "2432 E 15th St",
    city: "Tulsa",
    state: "OK",
    zip: "74104",
    country: "USA",
    phone: "(918) 663-3210",
    website: "https://www.tulsaband.com/",
    latitude: 36.14050032326517,
    longitude: -95.95683820462608,
  },
  {
    name: "Beaverton Music Services",
    address: "12480 SW 1st St",
    city: "Beaverton",
    state: "OR",
    zip: "97005",
    country: "USA",
    phone: "(503) 643-5431",
    website: "https://www.beavertonmusic.net/",
    latitude: 45.48678031894924,
    longitude: -122.80457582805882,
  },
  {
    name: "Taylor Music",
    address: "513 S. Main St.",
    city: "Aberdeen",
    state: "SD",
    zip: "57401",
    country: "USA",
    phone: "(605) 225-1335",
    website: "http://www.1800usaband.com/",
    latitude: 45.45965650165383,
    longitude: -98.4878274196105,
  },
  {
    name: "Amro Music Stores Inc",
    address: "2918 Poplar Ave.",
    city: "Memphis",
    state: "TN",
    zip: "38111",
    country: "USA",
    phone: "(901) 323-8888",
    website: "https://amromusic.com/",
    latitude: 35.134319780672115,
    longitude: -89.96441360280964,
  },

  {
    name: "Melhart Music Center",
    address: "3325 N. 10th St.",
    city: "McAllen",
    state: "TX",
    zip: "78501",
    country: "USA",
    phone: "(956) 682-6147",
    website: "http://www.melhart.com/",
    latitude: 26.233817404416893,
    longitude: -98.22553600307742,
  },
  {
    name: "Music & Arts #6915",
    address: "1428 W Pipeline Rd",
    city: "Hurst",
    state: "TX",
    zip: "76053",
    country: "USA",
    phone: "817 595 1511",
    website: "https://stores.musicarts.com/tx/hurst/music-store-6915.html",
    latitude: 32.82437728535944,
    longitude: -97.2033455736829,
  },
  {
    name: "Sam Ash Music #59",
    address: "25 NE Loop 410",
    city: "San Antonio",
    state: "TX",
    zip: "78216",
    country: "USA",
    phone: "(210) 530-9777",
    website: "https://locations.samash.com/tx/san-antonio/59/",
    latitude: 29.52157172946416,
    longitude: -98.49376097568832,
  },
  {
    name: "Sam Ash Music #65",
    address: "10838 N. Central Expressway",
    city: "Dallas",
    state: "TX",
    zip: "75231",
    country: "USA",
    phone: "(214) 368-6666",
    website: "https://locations.samash.com/tx/dallas/65/",
    latitude: 32.89783082271496,
    longitude: -96.76797182573438,
  },
  {
    name: "Summerhays Music Center",
    address: "5450 S. Green St.",
    city: "Murray",
    state: "UT",
    zip: "84123",
    country: "USA",
    phone: "(801) 268-4446",
    website: "http://www.summerhaysmusic.com/",
    latitude: 40.652212632015946,
    longitude: -111.90317830203996,
  },
  {
    name: "Kennelly Keys Music",
    address: "4918 196th St. SW",
    city: "Lynnwood",
    state: "WA",
    zip: "98036",
    country: "USA",
    phone: "(425) 771-7020",
    website: "http://www.kennellykeysmusic.com/",
    latitude: 47.82088449412366,
    longitude: -122.29966985772111,
  },

  {
    name: "Ted Brown Music Co.",
    address: "6228 Tacoma Mall Blvd.",
    city: "Tacoma",
    state: "WA",
    zip: "98409",
    country: "USA",
    phone: "(253) 272-3211",
    website: "https://www.tedbrownmusic.com/topic/tacoma",
    latitude: 47.200093692618154,
    longitude: -122.46339157139575,
  },
  {
    name: "Heid Music Company/Appleton",
    address: "308 E. College Ave.",
    city: "Appleton",
    state: "WI",
    zip: "54911",
    country: "USA",
    phone: "(920) 734-1969",
    website: "https://www.heidmusic.com/",
    latitude: 44.26240913955867,
    longitude: -88.40236130558046,
  },
  {
    name: "Instrumental Music Co.",
    address: "2986 Voyager Dr",
    city: "Green Bay",
    state: "WI",
    zip: "54311",
    country: "USA",
    phone: "920 857 3923",
    website: "https://www.musicimc.com/",
    latitude: 44.48576032665994,
    longitude: -87.93393724789452,
  },

  {
    name: "Schmitt Music, Inc.",
    address: "1409 Washington Ave.",
    city: "Racine",
    state: "WI",
    zip: "53403",
    country: "USA",
    phone: "(262) 634-5637",
    website: "N/A",
    latitude: 42.7287377,
    longitude: -87.7909716,
  },

  /*INTERNATIONAL*/ /*INTERNATIONAL*/ /*INTERNATIONAL*/ /*INTERNATIONAL*/
  /*INTERNATIONAL*/ /*INTERNATIONAL*/ /*INTERNATIONAL*/ /*INTERNATIONAL*/
  /*INTERNATIONAL*/ /*INTERNATIONAL*/ /*INTERNATIONAL*/ /*INTERNATIONAL*/

  {
    name: "The Music Place",
    address: "339 Clarendon St",
    city: "South Melbourne",
    state: "VIC",
    zip: "3205",
    country: "Australia",
    phone: "+61 3 9699 9099",
    website: "http://musicplace.com.au/",
    latitude: -37.834713388846204,
    longitude: 144.96167664146452,
  },

  {
    name: "Schageral Music GmbhH",
    address: "Hörsdorf 7",
    city: "Mank",
    state: "Lower Austria",
    zip: "3240",
    country: "Austria",
    phone: "+43 2755 23020",
    website: "http://www.schagerl.com/",
    latitude: 48.11741238717772,
    longitude: 15.330189427023313,
  },
  {
    name: "Votruba Musikinstrumente GmbH Vienna",
    address: "Lerchenfelder Gürtel 4",
    city: "Wien",
    state: "Vienna",
    zip: "A-1070",
    country: "Austria",
    phone: "+43 1 5237473",
    website: "https://www.votruba-musik.at/",
    latitude: 48.20476972895299,
    longitude: 16.337627668866343,
  },
  {
    name: "Ébano Instrumentos de Sopro",
    address: "R. Teodoro Sampaio, 784",
    city: "Jardim Paulista",
    state: "São Paulo",
    zip: "05406-000",
    country: "Brazil",
    phone: "++55 11 3891 0496",
    website: "http://www.ebanomusic.com.br/",
    latitude: -23.557465946336272,
    longitude: -46.67785081663644,
  },

  {
    name: "The Bandstand Ltd.",
    address: "4824 93 Ave NW",
    city: "Edmonton",
    state: "AB",
    zip: "T6B-2P8",
    country: "Canada",
    phone: "+780-465-7264",
    website: "http://www.bandstand.ca/",
    latitude: 53.530797351955655,
    longitude: -113.41174504061914,
  },

  {
    name: "Cosmo Music - The Musical Instrument Superstore",
    address: "10 Via Renzo Dr",
    city: "Richmond Hill",
    state: "ON",
    zip: "L4S 0B6",
    country: "Canada",
    phone: "+18004633000",
    website: "https://cosmomusic.ca/",
    latitude: 43.89095560163591,
    longitude: -79.39309433185916,
  },
  {
    name: "Counterpoint Musical Services",
    address: "39 Riviera Dr Unit 6",
    city: "Markham",
    state: "ON",
    zip: "L3R 8N4",
    country: "Canada",
    phone: "905-415-0515",
    website: "https://counterpointmusic.ca/",
    latitude: 43.8308537037831,
    longitude: -79.34566461597596,
  },

  {
    name: "Long & McQuade Musical Instruments",
    address: "225 58 Ave SE",
    city: "Calgary",
    state: "AB",
    zip: "T2H 0N8",
    country: "Canada",
    phone: "+403-244-5555",
    website: "https://www.long-mcquade.com/location/Alberta/Calgary/",
    latitude: 51.000915937075796,
    longitude: -114.06065661443098,
  },

  {
    name: "Long & McQuade",
    address: "925 Bloor St. W",
    city: "Toronto",
    province: "Ontario",
    zip: "M6H 1L5",
    country: "Canada",
    phone: "+416-588-7886",
    website: "https://www.long-mcquade.com/location/Ontario/Toronto/",
    latitude: 43.66160191989962,
    longitude: -79.4271838030866,
  },
  {
    name: "Long & McQuade",
    address: "368 Terminal Ave",
    city: "Vancouver",
    province: "British Columbia",
    zip: "V6A 3W9",
    country: "Canada",
    phone: "+604-734-4886",
    website:
      "https://www.long-mcquade.com/location/British_Columbia/Vancouver/",
    latitude: 49.271929974995686,
    longitude: -123.09612948690706,
  },
  {
    name: "Massullo Music",
    address: "4242-B E. Hastings St.",
    city: "Burnaby",
    province: "British Columbia",
    zip: "V5C-2J6",
    country: "Canada",
    phone: "+604-294-1777",
    website: "http://massullomusic.com/",
    latitude: 49.28104355145454,
    longitude: -123.01018631759355,
  },

  {
    name: "Central Conservatory of Music",
    address: "43 Bao Jia Jie, Xicheng District",
    city: "Beijing",
    zip: "100032",
    country: "China",
    phone: "NA",
    website: "http://en.ccom.edu.cn/2020/",
    latitude: 39.9035641780544,
    longitude: 116.37455403141197,
  },
  {
    name: "Thyme Yueh Industry Co., Ltd.",
    address: "3F, No. 16, Ln. 15, Yongchun St.",
    city: "Lukang Township",
    state: "Changhua County",
    zip: "50546",
    country: "Taiwan",
    latitude: 24.063426183420273,
    longitude: 120.42902527632596,
  },
  {
    name: "Xuan-Xuan Co., Ltd.",
    address: "No. 34, Ln. 283, Zhongzheng Rd.",
    city: "Yonghe District",
    state: "New Taipei City",
    zip: "23449",
    country: "Taiwan",
    latitude: 25.000790125710957,
    longitude: 121.51684774073941,
  },

  {
    name: "CASA JAYES - CONMÚSICA Sede principal",
    address: "Cl. 6 Sur #51-49, Guayabal",
    city: "Medellín",
    state: "Antioquia",
    zip: "4709",
    country: "Colombia",
    phone: "+57 315 4349135",
    website: "http://www.casajayes.com/",
    latitude: 6.203445244146391,
    longitude: -75.58579691874954,
  },
  {
    name: "Saxofonværkstedet",
    address: "Kærvej 15",
    city: "Åbyhøj",
    state: "Aarhus",
    zip: "8230",
    country: "Denmark",
    phone: "+45 30 69 54 70",
    website: "http://www.saxofon.dk/",
    latitude: 56.144957874474606,
    longitude: 10.162752146739525,
  },

  {
    name: "Howarth of London",
    address: "31-35 Chiltern St.",
    city: "London",
    state: "N/A",
    zip: "W1U 7PN",
    country: "United Kingdom",
    phone: "+44 20 7935 2407",
    website: "http://www.howarthlondon.com/",
    latitude: 51.51942209124755,
    longitude: -0.1551494259113463,
  },
  {
    name: "Soitin Laine Oy Turku",
    address: "Maariankatu 10",
    city: "Turku",
    state: "N/A",
    zip: "20100",
    country: "Finland",
    phone: "+358 9 4366840",
    website: "http://www.soitinlaine.fi/",
    latitude: 60.45338770829176,
    longitude: 22.26533021178053,
  },
  {
    name: "Arpèges",
    address: "123 Rue Lamarck",
    city: "Paris",
    state: "N/A",
    zip: "75018",
    country: "France",
    phone: "+33 1 53 06 39 40",
    website: "http://www.arpeges.fr/",
    latitude: 48.89440002687823,
    longitude: 2.3293729689416405,
  },
  {
    name: "Mister Music Musikinstrumente GmbH",
    address: "Brambach 29",
    city: "Schramberg",
    state: "N/A",
    zip: "78713",
    country: "Germany",
    phone: "+49 7422 99100",
    website: "http://www.mistermusic-profishop.de/",
    latitude: 48.24061601526414,
    longitude: 8.432748282880482,
  },
  {
    name: "Musik-Atelier Gabel GmbH",
    address: "Am Flüßchen 4",
    city: "Erfurt",
    state: "N/A",
    zip: "99091",
    country: "Germany",
    phone: "+49 361 7912724",
    website: "http://www.musik-gabel.de/",
    latitude: 51.02031779062471,
    longitude: 10.995236995977542,
  },
  {
    name: "Musik-Bertram",
    address: "Friedrichring 9",
    city: "Freiburg im Breisgau",
    state: "N/A",
    zip: "79098",
    country: "Germany",
    phone: "+49 761 2730900",
    website: "http://www.musik-bertram.com/",
    latitude: 47.998477500848885,
    longitude: 7.851873997693091,
  },
  {
    name: "PMS - Professional Music Shop",
    address: "Gärtnerstraße 122",
    city: "Hamburg",
    state: "N/A",
    zip: "20253",
    country: "Germany",
    phone: "+49 40 4910088",
    website: "http://www.pms-music.de/",
    latitude: 53.57866306995232,
    longitude: 9.962300726783988,
  },
  {
    name: "Tom Lee Music",
    address: "1-9 Cameron Ln",
    city: "Tsim Sha Tsui",
    state: "Kowloon",
    zip: "N/A",
    country: "Hong Kong",
    phone: "+852 2762 6900",
    website: "http://www.tomleemusic.com.hk/",
    latitude: 22.30010953912973,
    longitude: 114.17284165732241,
  },
  {
    name: "Fon-Trade Music",
    address: "Kiss Jozsef utca. 10-14",
    city: "Budapest",
    state: "N/A",
    zip: "1081",
    country: "Hungary",
    phone: "+36 30 488 6622",
    website: "https://www.fontrademusic.hu/",
    latitude: 47.49622643603177,
    longitude: 19.075148124655932,
  },
  {
    name: "Marom Musical Instruments",
    address: "D'Mall, 1 Jabotinsky St.",
    city: "Ramat-Gan",
    state: "N/A",
    zip: "5252001",
    country: "Israel",
    phone: "+972 3 612 4466",
    website: "http://www.marom-music.com/",
    latitude: 32.08304230351696,
    longitude: 34.80021069593749,
  },
  {
    name: "Ripamonti Musical Instruments",
    address: "Via Fiume 5",
    city: "Paderno Dugnano",
    state: "MI",
    zip: "20037",
    country: "Italy",
    phone: "+39-02-91-89-696",
    website: "https://www.ripamusic.com/",
    latitude: 45.56209126903449,
    longitude: 9.158092926424155,
  },
  {
    name: "Raffaele Inghilterra",
    address: "Via Giuseppe Raffaelli 71",
    city: "Gragnano",
    state: "NA",
    zip: "80054",
    country: "Italy",
    phone: "+39 081 801 2069",
    website: "http://www.raffaeleinghilterra.it/",
    latitude: 40.69367729145147,
    longitude: 14.527551897391943,
  },
  {
    name: "Select International",
    address: "8-2 Kitakokubun 1-Chome",
    city: "Ichikawa, Chiba Pref",
    state: "N/A",
    zip: "272-0836",
    country: "Japan",
    phone: "+047-374-0792",
    website: "http://www.select-inter.com/",
    latitude: 35.7282952,
    longitude: 139.9218634,
  },
  {
    name: "ACC MUSIC",
    address: "1485-12 Seocho-dong",
    city: "Seoul",
    state: "Seocho-gu",
    zip: "N/A",
    country: "South Korea",
    phone: "+82 70 7726 8742",
    website: "http://www.accmusic.com/",
    latitude: 37.48323607288244,
    longitude: 127.01005065309316,
  },
  {
    name: "Won Music",
    address: "89 Wonteoaraet-gil, Cheonggye-dong",
    city: "Uiwang-si",
    state: "Gyeonggi-do",
    zip: "16006",
    country: "South Korea",
    phone: "+82 31 444 8838",
    website: "http://www.wonmusic.co.kr/",
    latitude: 37.3954321877004,
    longitude: 127.02225053973334,
  },
  {
    name: "Sae Eum Musical Instrument Co.",
    address: "B254, 2F, Nakwon Arcade, Nakwon-dong",
    city: "Jongno-gu",
    state: "Seoul",
    zip: "N/A",
    country: "South Korea",
    phone: "+82 2 763 6252",
    website: "http://www.saeeum.com/",
    latitude: 37.62719293012494,
    longitude: 126.97638838166904,
  },
  {
    name: "Misung S.I.",
    address: "Rm. 341, Nakwon Bldg. 3F, 284-6",
    city: "Nakwon-dong, Chongro-Gu",
    state: "Seoul",
    zip: "N/A",
    country: "South Korea",
    phone: "+82 2 2274 3425",
    website: "N/A",
    latitude: 37.56971880742885,
    longitude: 126.99533591447563,
  },

  {
    name: "Juliard Musical Instruments",
    address: "2F, 262, Hyoryeong-ro, Seocho-gu",
    city: "Seoul",
    state: "N/A",
    zip: "06716",
    country: "South Korea",
    phone: "N/A",
    website: "N/A",
    latitude: 37.48499811654369,
    longitude: 127.01314329347512,
  },

  {
    name: "Frullato Musical",
    address: "Ensenada Grande 1B, Ensenada Grande 1B col. Fovissste",
    city: "Guaymas",
    state: "Sonora",
    zip: "85440",
    country: "Mexico",
    phone: "+52 62 2161 2009",
    website: "https://www.facebook.com/frullatomusical/",
    latitude: 27.925342300420255,
    longitude: -110.92209232902516,
  },
  {
    name: "Clave Musica",
    address: "Abasolo 1001",
    city: "Zacatecas Centro",
    state: "Zacatecas",
    zip: "98000",
    country: "Mexico",
    phone: "+52 49 2922 9639",
    website: "https://www.clavemusica.com",
    latitude: 22.780300194142544,
    longitude: -102.57150494602654,
  },
  {
    name: "Harmonía",
    address: "Mirador Orquídeas No. 3, col. Cerro Colorado, C.P. 91026. Xalapa, Veracruz, México",
    city: "Xalapa Enríquez",
    state: "Veracruz",
    zip: "91000",
    country: "Mexico",
    phone: "+52 22 8244 3124",
    website: "https://www.facebook.com/harmoniaxalapa?mibextid=ZbWKwL",
    latitude: 19.54321464622563,
    longitude: -96.9420832594816,
  },
  {
    name: "MHAUS LEMUS",
    address:
      "C. Metalurgia 2920, Parque Industrial El Álamo, Alamo Industrial, 45593 San Pedro",
    city: "Tlaquepaque",
    state: "Jalisco",
    zip: "45593",
    country: "Mexico",
    phone: "+52 33 3613 0050",
    website: "http://mhlemus.com.mx/",
    latitude: 20.623137620341886,
    longitude: -103.32583774587589,
  },
  {
    name: "Musicales Del Valle",
    address: "Av Al. Hidalgo 21, ",
    city: "70420 Teotitlán del Valle",
    state: "Oaxaca",
    zip: "70420",
    country: "Mexico",
    phone: "+52 95 1524 4188",
    website: "https://www.facebook.com/musicalesdelvalle",
    latitude: 17.029505641709616,
    longitude: -96.51757644618841,
  },

  {
    name: "Ocean Records Music Center",
    address:
      "Calzada Aviacion #1101, Local 7, Plaza Centenario, Col Santa Maria",
    city: "Mexicali",
    state: "Baja California",
    zip: "21240",
    country: "Mexico",
    phone: "+52 68 6566 5389",
    website: "http://oceanrecords.com.mx/",
    latitude: 32.65914365489173,
    longitude: -115.42852820579056,
  },
  {
    name: "Olvera Music",
    address: "Calle Francisco I. Madero 63, Zona Centro",
    city: "Guadalajara",
    state: "Jalisco",
    zip: "44100",
    country: "Mexico",
    phone: "+52 33 3614 4256",
    website: "https://www.olveramusic.com.mx/",
    latitude: 21.740928369815563,
    longitude: -103.31127434363464,
  },
  {
    name: "Jak Music",
    address: "Parque Residencial Monteverde",
    city: "Castilla",
    state: "Piura",
    zip: "20003",
    country: "Peru",
    phone: "+51 952 101 856",
    website: "https://www.facebook.com/JAKMUSIC26",
    latitude: -5.230064052234164,
    longitude: -80.63648594025992,
  },
  {
    name: "Maderas y Metales",
    address: "756 Jirón Angaraes",
    city: "Cercado de Lima",
    state: "Municipalidad Metropolitana de Lima",
    zip: "15001",
    country: "Peru",
    phone: "+51 994 181 996",
    website: "https://www.maderasymetales.com/",
    latitude: -12.046377673917643,
    longitude: -77.04172967301659,
  },

  {
    name: "Red Musik Peru",
    address: "Teodoro Cardenas 332",
    city: "Santa Beatriz",
    state: "Lince, Lima",
    zip: "15086",
    country: "Peru",
    phone: "+51 986787577",
    website: "https://redmusikperu.com",
    latitude: -12.077403116102406,
    longitude: -77.03356210802183,
  },

  {
    name: "Ricardo González Marruffo E.I.R.L",
    address: "Av. Nicolás de Piérola 265",
    city: "Lima 01",
    state: "N/A",
    zip: "15001",
    country: "Peru",
    phone: "+51 1 4246679",
    website: "http://www.gonzalezmarruffo.com/historia.html",
    latitude: -12.046912658805738,
    longitude: -77.04111700704613,
  },
  {
    name: "Intermusica",
    address: "Jr. Carabaya 319, Mezzanine 105",
    city: "Lima 01",
    state: "N/A",
    zip: "15001",
    country: "Peru",
    phone: "+51 1 4272320",
    website: "https://intermusicaperu.negocio.site/",
    latitude: -12.047035114844036,
    longitude: -77.03026843033619,
  },
  {
    name: "SISON ROS Music Center",
    address: "#51 14th Street, Brgy. Mariana",
    city: "Quezon City",
    state: "Manila",
    zip: "N/A",
    country: "Philippines",
    phone: "+63 2 696 1356",
    website: "https://www.facebook.com/ROSMusicCenter",
    latitude: 14.5995124,
    longitude: 120.9842195,
  },

  {
    name: "Piotrowski Music",
    address: "Andrzeja Szwalbego 2/5",
    city: "Bydgoszcz",
    state: "N/A",
    zip: "85-080",
    country: "Poland",
    phone: "+48 601 638 818",
    website: "http://www.piotrowskimusic.pl/",
    latitude: 53.12883987535217,
    longitude: 18.009503457401543,
  },
  {
    name: "Intrada",
    address: "Wiśniowa 19",
    city: "Kielce",
    state: "N/A",
    zip: "25-552",
    country: "Poland",
    phone: "+48 604 875 714",
    website: "http://www.intrada.com.pl/",
    latitude: 50.88725118132137,
    longitude: 20.634460816868224,
  },
  {
    name: "Fly Music SRL",
    address: "Strada Florilor 1",
    city: "Bosanci",
    state: "N/A",
    zip: "727045",
    country: "Romania",
    phone: "+40 374 494 494",
    website: "https://www.flymusic.ro/",
    latitude: 47.599284991770084,
    longitude: 26.3095662932538,
  },
  {
    name: "Gallery Trumpets",
    address: "Avenida Dolores Mosquera, 28",
    city: "Caldas De Reis",
    state: "Pontevedra",
    zip: "36650",
    country: "Spain",
    phone: "+34 986 54 11 15",
    website: "http://gallerytrumpets.com/",
    latitude: 42.60756462571274,
    longitude: -8.64495481602542,
  },
  {
    name: "MUSIK GRIMM - Swiss Clarinets & Saxophones",
    address: "Master-Repairshop, Pflanzschulstrasse 30",
    city: "Winterthur",
    state: "N/A",
    zip: "CH-8400",
    country: "Switzerland",
    phone: "+41 (0)52 238 00 40",
    website: "https://musikgrimm.ch/",
    latitude: 47.6730626027012,
    longitude: 8.809778302099884,
  },

  {
    name: "Musikatekier Willisau GmbH",
    address: "Ettiswilerstrasse 33A",
    city: "Willisau",
    state: "N/A",
    zip: "6130",
    country: "Switzerland",
    phone: "+41 41 970 09 10",
    website: "http://www.musikatelier.ch/",
    latitude: 47.12655126494206,
    longitude: 7.996916197655052,
  },
  {
    name: "Musikhaus Krebs AG",
    address: "Untergasse 36",
    city: "Biel",
    state: "N/A",
    zip: "2502",
    country: "Switzerland",
    phone: "+41 32 322 93 13",
    website: "http://www.musik-krebs.ch/",
    latitude: 47.162416618919664,
    longitude: 7.258558325595684,
  },

  {
    name: "Narita Industrial CO., Ltd.",
    address: "411 No 17, Lane 2140 South Kuang Hsing Rd.",
    city: "Taichung Hsien",
    state: "Taiping District",
    zip: "N/A",
    country: "Taiwan",
    phone: "+886 42 270 5416",
    website: "http://www.unisonsax.com",
    latitude: 24.121652586945455,
    longitude: 120.6625051100024,
  },
  {
    name: "KAI Eastern Taiwan Inc.",
    address: "125, Lane 482, Ta-Tung Road Sec 2",
    city: "Tainan City",
    state: "N/A",
    zip: "702",
    country: "Taiwan",
    phone: "+886 6 214 0016",
    website: "https://www.facebook.com/kaieasterntaiwan/",
    latitude: 23.01346413612245,
    longitude: 120.2280220491657,
  },
  {
    name: "Yong Seng Watana Trading Co., Ltd.",
    address: "22/1-4 Arun Amarin Road, Arun Amarin, Bangkok Noi",
    city: "Bangkok",
    state: "N/A",
    zip: "10700",
    country: "Thailand",
    phone: "+66 2 435 5364",
    website: "https://www.yongsengmusical.com/",
    latitude: 13.767222273983341,
    longitude: 100.48451360674619,
  },
  {
    name: "De Saxofoonwinkel",
    address: "Grote Kerkhof 29",
    city: "Deventer",
    state: "KV",
    zip: "7411",
    country: "The Netherlands",
    phone: "+31 570 612 844",
    website: "http://www.saxofoonwinkel.nl/",
    latitude: 52.25175240259371,
    longitude: 6.154015124870469,
  },
];

